.timeline {
  .spin {
    // Reverse the arrows, so that we spin clockwise.
    scale: 1 -1;
  }
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .timeline :global(.Timeline-Item):hover {
    .spin {
      --timeline-spin-period: 4s;

      -webkit-animation: spin var(--timeline-spin-period) linear infinite;
      -moz-animation: spin var(--timeline-spin-period) linear infinite;
      animation: spin var(--timeline-spin-period) linear infinite;
    }
  }
}

@media (prefers-reduced-motion) {
  .timeline :global(.Timeline-Item):hover {
    .spin {
      -webkit-animation: none;
      -moz-animation: none;
      animation: none;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
